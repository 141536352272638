  // 1. Create an array of quotes. Each quote object can have "text" and "author."
  const quotes = [
    { 
      text: '"You never learn by doing something right because you already know how to do it. You only learn from making mistakes and correcting them."', 
      author: "-- Russell Ackoff" 
    },
    { 
      text: '"What can be asserted without evidence can also be dismissed without evidence."', 
      author: "-- Christopher Hitchens" 
    },
    { 
      text: '"Any organisation that design a system (defined broadly) will produce a design whose structure is a copy of the organisation\'s communication structure."', 
      author: "-- Conway's Law, from Melvin Conway"
    },
    { 
      text: '"Every though decision comes down to a probability, then certainty is an impossibility."', 
      author: "-- Barak Obama"
    },
    { 
      text: '"With exceptions, people\'s ideological commitments are laughably weak. They infer their beliefs from their tribe, not the other way around."', 
      author: "-- Janan Ganesh"
    },
    { 
      text: '"Tanto pi\xF9 bello sar\xE0 un carattere, quanto pi\xF9 avr\xE0 regolarit\xE0, nettezza, buon gusto e grazia."', 
      author: "-- Giambattista Bodoni"
    },
    { 
      text: '"If you have four groups working on a compiler, you\'ll get a 4-pass compiler."', 
      author: "-- Eric S. Raymond"
    },
    // ... add more quotes
  ];

  // 2. Listen for the DOM to load
  document.addEventListener('DOMContentLoaded', function() {
    // 3. Select the quote elements
    const quoteTextEl = document.querySelector('.hero .quote p');
    const quoteAuthorEl = document.querySelector('.hero .quote footer');

    // 4. Pick a random quote
    const randomIndex = Math.floor(Math.random() * quotes.length);
    const randomQuote = quotes[randomIndex];

    // 5. Inject quote text and author into the DOM
    quoteTextEl.textContent = randomQuote.text;
    quoteAuthorEl.textContent = randomQuote.author;
  });